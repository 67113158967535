import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";

function MemberCard(props) {
    return (
        <Card className="project-card-view">
            <Card.Img variant="top" src={props.avatar} alt="card-img" />
            <Card.Body>
                <Card.Title>{props.name}</Card.Title>
                <Card.Text style={{ textAlign: "left" }}>
                    {props.introduction}
                </Card.Text>
                <Button variant="primary" href={props.link} target="_blank">
                    <BiLinkExternal /> &nbsp;
                    {"Learn more"}
                </Button>
            </Card.Body>
        </Card>
    );
}
export default MemberCard;
