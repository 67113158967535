import React from "react";
import Typewriter from "typewriter-effect";

function TypeWriter() {
    return (
        <Typewriter
            options={{
                strings: [
                    "Advanced Atomic Structure and Spectroscopic Analysis",
                    "Atomic Image Deep Analysis and Processing",
                    "Novel Low-Dimensional Materials Synthesis"
                ],
                autoStart: true,
                loop: true,
                delay: 1,
                deleteSpeed: 10,
            }}
        />
    );
}

export default TypeWriter;