import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
// import logo from "../Assets/logo.png";
// import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
// import { CgGitFork } from "react-icons/cg";
// import { ImBlog } from "react-icons/im";
// import {
//     AiFillStar,
//     AiOutlineHome,
//     AiOutlineFundProjectionScreen,
//     AiOutlineUser,
// } from "react-icons/ai";

// import { CgFileDocument } from "react-icons/cg";

function NavBar() {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);

    return (
        <Navbar
            expanded={expand}
            fixed="top"
            expand="md"
            className={navColour ? "sticky" : "navbar"}
        >
            <Container>
                <Navbar.Brand href="/" className="d-flex">
                    {/*<img src={logo} className="img-fluid logo" alt="brand" />*/}
                    The Zhao Lab
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : "expanded");
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                {/*<AiOutlineHome style={{ marginBottom: "2px" }} /> Home*/}
                                Home
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/team"
                                onClick={() => updateExpanded(false)}
                            >
                                {/*<AiOutlineUser style={{ marginBottom: "2px" }} /> Team*/}
                                Team
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/research"
                                onClick={() => updateExpanded(false)}
                            >
                                {/*<AiOutlineFundProjectionScreen*/}
                                {/*    style={{ marginBottom: "2px" }}*/}
                                {/*/>{" "}*/}
                                {/*Projects*/}
                                Research
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/publications"
                                onClick={() => updateExpanded(false)}
                            >
                                {/*<CgFileDocument style={{ marginBottom: "2px" }} /> Resume*/}
                                Publications
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/news"
                                onClick={() => updateExpanded(false)}
                            >
                                {/*<CgFileDocument style={{ marginBottom: "2px" }} /> Resume*/}
                                News
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/jobs"
                                onClick={() => updateExpanded(false)}
                            >
                                {/*<CgFileDocument style={{ marginBottom: "2px" }} /> Resume*/}
                                Jobs
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/gallery"
                                onClick={() => updateExpanded(false)}
                            >
                                {/*<CgFileDocument style={{ marginBottom: "2px" }} /> Resume*/}
                                Gallery
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/contact"
                                onClick={() => updateExpanded(false)}
                            >
                                {/*<CgFileDocument style={{ marginBottom: "2px" }} /> Resume*/}
                                Contact
                            </Nav.Link>
                        </Nav.Item>


                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
