import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import avatar from "../../Assets/Home/prof_zhao.jpg";
import googleScholar from "../../Assets/Home/icons8-google-scholar-14.svg";
import Tilt from "react-parallax-tilt";
// import {
//     AiFillGoogleSquare
//     AiFillGithub,
//     AiOutlineTwitter,
//     AiFillInstagram,
// } from "react-icons/ai";
// import { FaLinkedinIn } from "react-icons/fa";

function Introduction() {
    return (
        <Container fluid className="home-about-section" id="introduction">
            <Container>
                <Row style={{"height": "75vh"}}>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>
                            ABOUT <span className="purple"> DR. XIAOXU ZHAO </span>
                        </h1>
                        <p className="home-about-body">
                            Dr. Xiaoxu Zhao is currently an Assistant Professor  in the School of Materials Science & Engineering, Peking University. He obtained his B.Eng (1st Class Honours) degree from Nanyang Technological University in 2014 and Ph.D. degree from National University of Singapore in 2018. He visited the STEM group at Oak Ridge National Lab as a visiting scholar from 2015 to 2016.  After that, he became a Research fellow at National University of Singapore from 2018 to 2020, and joined Nanyang Technological University after receiving the prestigious award Presidential Postdoc Fellowship as an independent principal investigator with 200,000 SGD start-up in 2020. He published over 130 peer-reviewed papers including Nature, Nat. Nanotechnol., Nat. Mater., Nat. Chem., Nat. Commun., etc with more than 8,000 citations，h-index 52. He is a young advisor board member for Infomat journal, and has been a referee for many high-profile journals. His main research interests are low dimensional materials, single atom catalysis, scanning transmission electron microscopy, electron energy loss spectroscopy, and advanced image processing.He was named in the MIT Technology Review TR35 (Asia-Pacific) 2022 and Forbes China· 100 Youth Returnee Elite 2022.
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        {/*<Tilt>*/}
                            <img src={avatar} className="img-fluid" alt="avatar" />
                        {/*</Tilt>*/}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h3>
                            Google Scholar
                        </h3>
                        <ul className="home-about-social-links">
                            <li className="social-icons">
                                <a
                                    href="https://scholar.google.com/citations?user=ja7lLksAAAAJ"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                >
                                    <img
                                        className="icon-colour home-social-icons"
                                        src={googleScholar}
                                        alt={"google scholar"}
                                    />
                                </a>
                            </li>
                        {/*    <li className="social-icons">*/}
                        {/*        <a*/}
                        {/*            href=""*/}
                        {/*            target="_blank"*/}
                        {/*            rel="noreferrer"*/}
                        {/*            className="icon-colour  home-social-icons"*/}
                        {/*        >*/}
                        {/*            <AiOutlineTwitter />*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        {/*    <li className="social-icons">*/}
                        {/*        <a*/}
                        {/*            href="https://www.linkedin.com/in/soumyajit4419/"*/}
                        {/*            target="_blank"*/}
                        {/*            rel="noreferrer"*/}
                        {/*            className="icon-colour  home-social-icons"*/}
                        {/*        >*/}
                        {/*            <FaLinkedinIn />*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        {/*    <li className="social-icons">*/}
                        {/*        <a*/}
                        {/*            href="https://www.instagram.com/s.o.u.m.y.a_j.i.t/"*/}
                        {/*            target="_blank"*/}
                        {/*            rel="noreferrer"*/}
                        {/*            className="icon-colour home-social-icons"*/}
                        {/*        >*/}
                        {/*            <AiFillInstagram />*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
export default Introduction;
