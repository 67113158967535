import React from "react";
import {Container} from "react-bootstrap";
import NewsItem from "./NewsItem";

const news = {
    "2023": [
        {
            "date": "2024-02-16",
            // "title": "News Title 3",
            "description": "Congratulations to Xiaocang Han’s paper which entitled \"Atomically Engineering Metal Vacancies in Monolayer Transition Metal Dichalcogenides\" has been accepted by Nature. Synthesis.! She is the co-first author of the paper. Thanks for her hard work."
        },
        {
            "date": "2023-11-09",
            // "title": "News Title 3",
            "description": "Professor Loh Kian Ping was invited to visit and exchange ideas at the School of Materials Science and Engineering at Peking University. Professor Loh Kian Ping made a brilliant academic report titled \"Topological Phase of Weyl Semimetal and Topological Insulators\"."
        },
        {
            "date": "2023-10-14",
            // "title": "News Title 3",
            "description": "Stephen J. Pennycook visited the research team."
        },
        {
            "date": "2023-09-18",
            // "title": "News Title 3",
            "description": "Warmly welcome Junxian Li to join the research group. Junxian is currently an undergraduate student from the School of Artificial Intelligence at Beijing Normal University. He will work with Xiang Chen and Yuan Meng on AI for Electron Microscopy."
        },
        {
            "date": "2023-08-31",
            // "title": "News Title 2",
            "description": "Welcome Dr. Zanlin Qiu! Dr. Qiu completed his undergraduate studies at Shanghai Jiao Tong University and pursued his Ph.D. at Ohio State University. He specializes in EM, particularly skilled in CBED. We are delighted to have Dr. Qiu on board with us!"
        },
        {
            "date": "2023-08-31",
            // "title": "News Title 3",
            "description": "Welcome Shangtian Yang, a new graduate student from Hsinchu, Taiwan, to join our research group. She graduated from East China University of Science and Technology with a bachelor's degree."
        },
        {
            "date": "2023-08-24",
            // "title": "News Title 3",
            "description": "Professor Xiaoxu Zhao and other faculty representatives went to Japan for academic exchanges."
        },
        {
            "date": "2023-08-10",
            // "title": "News Title 3",
            "description": "Welcome Dr. Ning Li to join our team as a postdoctoral fellow. Li Ning is a joint doctoral candidate from University of Science and Technology Beijing and National University of Singapore, specializing in the analysis of thin film materials using spherical aberration electron microscopy. We are delighted to have Dr. Ning Li on board with us!"
        },
        {
            "date": "2023-07-13",
            // "title": "News Title 3",
            "description": "Warmly welcome Yuan Meng to join our research group! Yuan Meng is currently an undergraduate student majoring in CS at Beijing Normal University and will be admitted to Peking University next year. She will promote our research in the field of integrating AI and EM to achieve new breakthroughs."
        },
        {
            "date": "2023-02-08",
            // "title": "News Title 3",
            "description": "Congratulations to Xiaocang Han’s paper which entitled \"A general thermodynamics-triggered competitive growth model to guide the synthesis of two-dimensional nonlayered materials\" has been accepted by Nat. Commun.! She is the co-first author of the paper. Thanks for her hard work."
        },
        {
            "date": "2023-02-03",
            // "title": "News Title 3",
            "description": "Dr. Xiaocang Han’s paper entitled \"Atomically Unveiling an Atlas of Polytypes in Transition-Metal Trihalides\" has now been accepted by JACS! This is the first paper that was done entirely within the groups in PKU. Congratulations!"
        },
    ]
}

function display_news(news_list) {
    return (
        news_list.map(news =>
            <NewsItem
                date={news["date"]}
                // title={news["title"]}
                description={news["description"]}
            />
        )
    )
}

function News() {
    return (
        <section>
            <Container fluid className={"project-section"}>
                <h1 className={"white-text"}>News</h1>
            </Container>
            <Container className={"home-research-section"}>
                <h3>2023</h3>
                {display_news(news["2023"])}
            </Container>
        </section>
    )
}

export default News;