import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";

function ResearchCard(props) {
    return (
        <Card className="project-card-view">
            <Card.Img variant="top" src={props.imgPath} alt="card-img" style={{"height": "33%"}}/>
            <Card.Body>
                <Card.Title style={{"height": "17%"}}>{props.title}</Card.Title>
                <Card.Text style={{ textAlign: "left", "height": "70%"}}>
                    {props.description}
                </Card.Text>
                <Button variant="primary" href={props.link} target="_blank">
                    <BiLinkExternal /> &nbsp;
                    {"View Research"}
                </Button>
            </Card.Body>
        </Card>
    );
}
export default ResearchCard;
