import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import ResearchCard from "./ResearchCard";
import research1 from "../../Assets/Home/research-1.png";
import research2 from "../../Assets/Home/research-2.png";
import research3 from "../../Assets/Home/research-3.png";


function Research() {
    return (
        <Container fluid className="home-research-section" id="research">
            <Container>
                <Row>
                    <Col md={12}>
                        <h1 style={{marginBottom: "50px", marginTop: "-10px"}}>
                            Our Research
                        </h1>
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    <Col md={4} className="project-card">
                        <ResearchCard
                            imgPath={research1}
                            title={"Advanced Atomic Structure and Spectroscopic Analysis"}
                            description={"Seeing and controlling single atoms are reigning paradigms in nanoscience and nanotechnology as popularized by Drexler (MIT) and Eigler (IBM). With advent of aberration corrected scanning transmission electron microscope (STEM), each single atoms can be unprecedentedly unveiled and manipulated by STEM via a non-destructive way. We are using state-of-the-art STEM techniques to study various atomic features in low-dimensional materials, including point defects, edge topologies, grain boundaries and their associated dynamics and reconstructions."}
                            link={"/Research"}
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ResearchCard
                            imgPath={research2}
                            title={"Atomic Image Deep Analysis and Processing"}
                            description={"Exotic physical and chemical properties of functional materials are predominantly determined by the collection of atomic bonding and topology of atoms. We are introducing new ideas in the field of computer vision and machine learning to provide more momentum to harness the wealth of big data and sophisticated information in STEM data analytics."}
                            link={"/Research"}
                        />
                    </Col>
                    <Col md={4} className="project-card">
                        <ResearchCard
                            imgPath={research3}
                            title={"Novel Low-Dimensional Materials Synthesis"}
                            description={"Since the discover of Graphene, low-dimensional materials grow rapidly during the past decades, hugely expanding the scope of possible phenomena to be explored in two dimensions. We are introducing new growth methods to synthesize novel 2D magnets, twisted 2D materials, and 2D quantum materials, offering new projections in this rapidly expanding field."}
                            link={"/Research"}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Research;