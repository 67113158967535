import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import markdownFile from '../../Assets/Jobs/description.md';
import {Col, Container, Row} from "react-bootstrap";

function Jobs() {
    const [markdown, setMarkdown] = React.useState('');

    React.useEffect(() => {
        fetch(markdownFile)
            .then((response) => response.text())
            .then((text) => setMarkdown(text));
    }, []);

    const CodeBlock = {
        code({ language, value }) {
            return (
                <SyntaxHighlighter style={docco} language={language}>
                    {value}
                </SyntaxHighlighter>
            );
        },
    };

    return (
        <section>
            <Container fluid className={"project-section"}>
                <h1 className={"white-text"}>Open Positions</h1>
            </Container>
            <Container fluid >
                <Row>
                    <Col md={2}></Col>
                    <Col md={8} className={"markdown-region"}>
                        <ReactMarkdown components={CodeBlock} children={markdown}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Jobs;
