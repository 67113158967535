import React from "react";
import {Container, Row, Col} from "react-bootstrap";


function NewsItem(props) {
    return (
        // <Row style={{"text-align": "left", "margin-top": "15px", "margin-bottom": "15px"}}>
        <Row style={{textAlign: "left", marginTop: "15px", marginBottom: "15px", fontFamily: "Poppins"}}>
            <h4 style={{color: "hsla(42, 89%, 36%, 1)"}}>{props.date}</h4>
            <br></br>
            <p>{props.description}</p>
        </Row>
    )
}
export default NewsItem;
