import React from "react";
import {Col, Container, Row} from "react-bootstrap";


function ContactPage() {
    return (
        <section>
            <Container fluid className={"project-section"}>
                <h1 className={"white-text"}>Contact Us</h1>
            </Container>
            <Container fluid className="contact-section" id="contact">
                <Container>
                    <Row>
                        <Col md={12} className="contact-info">
                            <section>
                                <h4>
                                    PEKING UNIVERSITY ZHAO LABORATORY / No.5 Yiheyuan Road, Beijing 100871, China
                                </h4>
                                <br></br>
                                <h4>
                                    Email: xiaoxuzhao at pku.edu.cn
                                </h4>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section>
    )
}

export default ContactPage;