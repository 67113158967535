import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import ReactHtmlParser from 'html-react-parser';

function makeProfBold(text) {
    return text.replace(/Xiaoxu Zhao/g, "<strong>Xiaoxu Zhao</strong>");
}

function PaperItem(props) {
    return (
        // <Container className={"paper-section"}>
            <Row style={{margin: "3em"}}>
                <Col md={4}>
                    <img src={props.image} alt={"paper"} style={{ maxWidth: '100%', height: 'auto' }}/>
                </Col>
                <Col md={8}>
                    <p style={{fontSize: 18, fontFamily: "Poppins", textAlign: 'left', color: "hsla(42, 89%, 36%, 1)"}}>
                        <strong>{props.index}</strong>. {props.title}
                    </p>
                    {/*<p dangerouslySetInnerHTML={{ __html: makeProfBold(props.author) }} />*/}
                    <p style={{fontSize: 14, fontFamily: "Poppins", textAlign: 'left'}}>
                        {ReactHtmlParser(makeProfBold(props.author))}
                    </p>
                    <p style={{fontSize: 18, fontFamily: "Poppins", textAlign: 'left'}}>
                        {props.reference} <a href={props.link} target={"_blank"} rel="noreferrer">[PDF]</a>
                    </p>
                </Col>
            </Row>
        // </Container>
    );
}

export default PaperItem;