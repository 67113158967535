import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import work1 from "../../Assets/Home/work_1.png";
import work2 from "../../Assets/Home/work_2.png";
import work3 from "../../Assets/Home/work_3.png";

import Introduction from "./Introduction";
import TypeWriter from "./TypeWriter";
import {Carousel} from "react-bootstrap";
import Research from "./Research";
import Contact from "./Contact";
import Highlight from "./Highlights";

function Home() {
    return (
        <section className={"homepage"}>
            <Container fluid className="home-section" id="home">
                <Container className="home-content">
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8} className="home-header">
                            <h1 style={{ paddingBottom: 15, color: 'black'}} className="heading">
                                Welcome to Dr. Xiaoxu Zhao's Homepage{" "}
                                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
                            </h1>

                            <h1 style={{ color: 'black'}} className="heading-name">
                                We work on
                            </h1>

                            <div style={{ padding: 50, textAlign: "left" }}>
                                <TypeWriter />
                            </div>
                        </Col>

                        {/*<Col md={5} style={{ paddingBottom: 20 }}>*/}
                        {/*    /!*<img*!/*/}
                        {/*    /!*    src={homeLogo}*!/*/}
                        {/*    /!*    alt="home pic"*!/*/}
                        {/*    /!*    className="img-fluid"*!/*/}
                        {/*    /!*    style={{ maxHeight: "450px" }}*!/*/}
                        {/*    /!*//*/}
                        {/*    <Carousel fade>*/}
                        {/*        <Carousel.Item>*/}
                        {/*            <img*/}
                        {/*                className="d-block w-100"*/}
                        {/*                src={work1}*/}
                        {/*                alt="First work"*/}
                        {/*            />*/}
                        {/*        </Carousel.Item>*/}
                        {/*        <Carousel.Item>*/}
                        {/*            <img*/}
                        {/*                className="d-block w-100"*/}
                        {/*                src={work2}*/}
                        {/*                alt="Second work"*/}
                        {/*            />*/}
                        {/*        </Carousel.Item>*/}
                        {/*        <Carousel.Item>*/}
                        {/*            <img*/}
                        {/*                className="d-block w-100"*/}
                        {/*                src={work3}*/}
                        {/*                alt="Third work"*/}
                        {/*            />*/}
                        {/*        </Carousel.Item>*/}
                        {/*    </Carousel>*/}

                        {/*</Col>*/}
                    </Row>
                </Container>
            </Container>
            <Research />
            <Introduction />
            <Highlight />
            <Contact />
        </section>
    );
}

export default Home;
