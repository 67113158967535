import React from 'react';

function PageNotFound(){
    return (
        <div class="error-page-section">
            {/*<h1>404 Error</h1>*/}
            {/*<h1>Page Not Found</h1>*/}
        </div>
    );
}

export default PageNotFound;