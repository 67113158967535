import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Team from "./components/Team/Team"
import Research from "./components/Research/Research"
import Publications from "./components/Publications/Publications";
import MyGallery from "./components/Gallery/Gallery";
import PageNotFound from "./components/404";
import ContactPage from "./components/Contact/Contact";
import News from "./components/News/News";

import ZiyiHan from "./components/Team/Members/ziyihan";
import XiaocangHan from "./components/Team/Members/xiaocanghan";
import ShengqiangWu from "./components/Team/Members/shengqiangwu";
import JunZhao from "./components/Team/Members/junzhao";
import NingLi from "./components/Team/Members/ningli";
import ZanlinQiu from "./components/Team/Members/zanlingqiu";
import KangshuLi from "./components/Team/Members/kangshuli";
import WenchaoHu from "./components/Team/Members/wenchaohu";
import SongHuang from "./components/Team/Members/songhuang";
import WingniCheng from "./components/Team/Members/wingnicheng";
import XiangChen from "./components/Team/Members/xiangchen";
import ShangtienYang from "./components/Team/Members/shangtienyang";
import XuanZhou from "./components/Team/Members/xuanzhou";
import YuanMeng from "./components/Team/Members/yuanmeng";
import JunxianLi from "./components/Team/Members/junxianli";
import YuLiang from "./components/Team/Members/yuliang";
import YeSun from "./components/Team/Members/yesun";
import QingduoWang from "./components/Team/Members/qingduowang";
import HuijunLiu from "./components/Team/Members/huijunliu";
import Jobs from "./components/Jobs/Jobs";


function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
      <Router>
        {/*<Preloader load={load} />*/}
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/team" element={<Team />} />
            <Route path="/research" element={<Research />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/gallery" element={<MyGallery />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/news" element={<News />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/member/xiaocanghan" element={<XiaocangHan />} />
            <Route path="/member/shengqiangwu" element={<ShengqiangWu />} />
            <Route path="/member/junzhao" element={<JunZhao/>} />
            <Route path="/member/ningli" element={<NingLi />} />
            <Route path="/member/zanlinqiu" element={<ZanlinQiu />} />
            <Route path="/member/kangshuli" element={<KangshuLi />} />
            <Route path="/member/wenchaohu" element={<WenchaoHu />} />
            <Route path="/member/songhuang" element={<SongHuang />} />
            <Route path="/member/ziyihan" element={<ZiyiHan />} />
            <Route path="/member/wingnicheng" element={<WingniCheng />} />
            <Route path="/member/xiangchen" element={<XiangChen />} />
            <Route path="/member/shangtienyang" element={<ShangtienYang />} />
            <Route path="/member/xuanzhou" element={<XuanZhou />} />
            <Route path="/member/yuanmeng" element={<YuanMeng />} />
            <Route path="/member/junxianli" element={<JunxianLi />} />
            <Route path="/member/yuliang" element={<YuLiang />} />
            <Route path="/member/YeSun" element={<YeSun />} />
            <Route path="/member/QingduoWang" element={<QingduoWang />} />
            <Route path="/member/HuijunLiu" element={<HuijunLiu />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
