import React from "react";
import {Container} from "react-bootstrap";
import ResearchCard from "./ResearchCard";
import research1 from "../../Assets/Home/research-1.png"
import research2 from "../../Assets/Home/research-2.png"
import research3 from "../../Assets/Home/research-3.png"

function Research() {
    return (
        <section>
            <Container fluid className={"project-section"}>
                <h1 className={"white-text"}>Research</h1>
            </Container>
            <Container className={"home-research-section"}>
                <ResearchCard
                    pos="left"
                    image={research1}
                    title="Advanced Atomic Structure and Spectroscopic Analysis"
                    description='Seeing atoms and controlling atoms are reigning paradigms in modern nanoscience and nanotechnology. In 1959 Richard P. Feynman in his famous talk "There is plenty of room at the bottom" predicted that one day we will be making things at the atomic scale. With the advent of advanced aberration correctors and stable lens systems in the past few decades, electron microscopes can readily observe atoms at the atomic scale and significantly help people understand the research at the atomic scale. In our lab, we are using state-of-the-art (scanning) transmission electron microscopy ((S)TEM) to unveil and control atoms down to the single-atom precision. Particularly we are focusing on low-dimensional materials, including Carbon-based 2D materials, 2D Ferroelectric Materials, 2D Magnets, Twisted 2D Materials, 2D MOF, 2D COF, etc. A variety of topological features and their corresponding dynamics will be unraveled at the atomic scale, including defects, edges, grain boundaries, stacking orders, etc. We aim to understand the functionalities of advanced 2D materials and systematically develop the structure-property relationships at the atomic scale.'
                />
                <ResearchCard
                    pos="right"
                    image={research2}
                    title="Atomic Image Deep Analysis and Processing"
                    description="Enabled by the advances in aberration-corrected electron microscopy, atomic-resolution real-space imaging of materials has allowed a direct structure-property investigation. Traditional ways of quantitative data analysis suffer from low yield and poor accuracy. New ideas in the field of computer vision and machine learning have provided more momentum to harness the wealth of big data and sophisticated information in (S)TEM data analytics, which has transformed (S)TEM from a localized characterization technique to a macroscopic tool with intelligence. Therefore, we are developing novel machine-learning frameworks that can rapidly extract a hierarchy of complex structural information from atomically resolved images with high throughput and veracity. We are combining machine learning and (S)TEM imaging which will be offering a suite of potential applications in new structure discovery, defect dynamics control, and nanofabrication under an electron beam. Deep learning will allow us to understand a much broader range of multiscale samples with functional imperfections and nontrivial topological phases."

                />
                <ResearchCard
                    pos="left"
                    image={research3}
                    title="Novel Low-Dimensional Materials Synthesis"
                    description="The rise of graphene and other atomically thin 2D materials has heralded a new branch of condensed-matter physics concerned with the description of electrons in atomically thin structures. The confined 2D materials offer a unique platform from which to explore the physics of topology and many-body phenomena. So far, there are over a few hundred types of 2D materials have been experimentally synthesized, and over 5000 potential compounds, as predicted by the theory, await to be explored. The atomic structures of 2D materials are rich and diverse, ranging from simple hexagonal and cubic lattices to topological nontrivial Kagome lattices. Therefore, different 2D materials exhibit rich and unique physical and chemical properties, which have been widely exploited in electronic devices, photonics, superconductors, catalysis, etc. In our group, we are focusing on synthesizing novel 2D materials with controlled structural defects, dopants, grain boundaries, and polymorphisms by chemical vapor deposition or molecular beam epitaxy. Combined with atomic resolution electron microscopy, we aim to functionalize 2D materials by defect engineering, strain engineering, phase engineering, stacking engineering, etc, at the atomic scale."
                />
            </Container>
        </section>
 )
}

export default Research;