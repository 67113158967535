import React from "react";
import {Container, Row, Col} from "react-bootstrap";


function ResearchCard(props) {
    if (props.pos === "left") {
        return (
            // <Container style={{"width": "100%"}}>
            //     <Row style={{"font-family": "", "height": "380px", "text-align": "left", "margin-top": "30px", "margin-bottom": "30px"}}>
            <Row className={"research-card"}>
                    <Col md={4}>
                        <img src={props.image} alt={"image"} width={"100%"} height={"auto"}/>
                    </Col>
                    <Col md={8}>
                        <h3>{props.title}</h3>
                        <p>
                            {props.description}
                        </p>
                    </Col>
                </Row>
            // </Container>
        );
    } else {
        return (
            // <Container style={{"width": "100%"}}>
            //     <Row style={{"height": "380px", "text-align": "left", "margin-top": "30px", "margin-bottom": "30px"}}>
            <Row className={"research-card"}>
                    <Col md={8}>
                        <h3>{props.title}</h3>
                        <p>
                            {props.description}
                        </p>
                    </Col>
                    <Col md={4}>
                        <img src={props.image} alt={"image"} width={"100%"} height={"auto"}/>
                    </Col>
                </Row>
            // </Container>
        );
    }

}
export default ResearchCard;
