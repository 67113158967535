import React from "react";
import Card from "react-bootstrap/Card";

function HighlightCard(props) {
    return (
        <a href={props.link} target={"_blank"}>
        <Card className="project-card-view">
            <Card.Img variant="top" src={props.imgPath} alt="card-img"  />
            <Card.Body>
                <Card.Title as={"p"}>{props.title}</Card.Title>
            </Card.Body>
        </Card>
        </a>
    );
}

export default HighlightCard;