import React from "react";
import {Col, Row, Container} from "react-bootstrap";
import MemberCard from "./MemberCard";
import PIAvatar from "../../Assets/Members/prof_zhao/portrait.jpg";
import PIAvatar2 from "../../Assets/Members/hanxiaocang/portrait.jpg";
import PIAvatar3 from "../../Assets/Members/wushengqiang/portrait.jpg";
import PIAvatar5 from "../../Assets/Members/zhaojun/portrait.jpg";
import PIAvatar6 from "../../Assets/Members/lining/portrait.jpg";
import PIAvatar7 from "../../Assets/Members/qiuzanlin/portrait.jpg";
import PIAvatar8 from "../../Assets/Members/likangshu/portrait.jpg";
import PIAvatar9 from "../../Assets/Members/huwenchao/portrait.jpg";
import PIAvatar10 from "../../Assets/Members/huangsong/portrait.jpg";
import PIAvatar11 from "../../Assets/Members/hanziyi/portrait.jpg";
import PIAvatar13 from "../../Assets/Members/wingnicheng/portrait.jpg";
import PIAvatar14 from "../../Assets/Members/chenxiang/portrait.jpg";
import PIAvatar15 from "../../Assets/Members/yangshangtian/portrait.jpg";
import PIAvatar16 from "../../Assets/Members/zhouxuan/portrait.jpg";
import PIAvatar17 from "../../Assets/Members/mengyuan/portrait.jpg";
import PIAvatar18 from "../../Assets/Members/lijunxian/portrait.jpg";
import PIAvatar19 from "../../Assets/Members/huijunliu/portrait.jpg";
import PIAvatar20 from "../../Assets/Members/qingduowang/portrait.jpg";
import PIAvatar21 from "../../Assets/Members/liangyu/portrait.jpg";
import PIAvatar22 from "../../Assets/Members/sunye/portrait.jpg";


const members = {
    "Principal Investigator": [
        {
            "name": "Xiaoxu Zhao (赵晓续)",
            "avatar": PIAvatar,
            "introduction": "" +
                "Assistant Professor in the Department of Materials Science & Engineering, Peking University" +
                "\nPhd (National University of Singapore)",
            "link": "/"
        }
    ],
    "RESEARCH FELLOW": [
        {
            "name": "Dr. XiaoCang Han (韩小藏)",
            "avatar": PIAvatar2,
            "introduction": "Postdoctoral Associate",
            "link": "/member/xiaocanghan"
        },
        {
            "name": "Dr. Shengqiang Wu (吴胜强)",
            "avatar": PIAvatar3,
            "introduction": "Postdoctoral Associate",
            "link": "/member/shengqiangwu"
        },
        {
            "name": "Dr. Jun Zhao (赵君)",
            "avatar": PIAvatar5,
            "introduction": "Postdoctoral Associate",
            "link": "/member/junzhao"
        },
        {
            "name": "Dr. Ning Li (李宁)",
            "avatar": PIAvatar6,
            "introduction": "Postdoctoral Associate",
            "link": "/member/ningli"
        },
        {
            "name": "Dr. Zanlin Qiu(邱赞霖)",
            "avatar":PIAvatar7,
            "introduction": "Postdoctoral Associate",
            "link": "/member/zanlinqiu"
        },
        {
            "name": "Kangshu Li (李康舒)",
            "avatar":PIAvatar8,
            "introduction": "Ph.D. Candidate",
            "link": "/member/kangshuli"
        },
        {
            "name": "Wenchao Hu (胡文超)",
            "avatar": PIAvatar9,
            "introduction": "Co-supervized with Prof Ruqiang Zou,\nPh.D. Candidate",
            "link": "/member/wenchaohu"
        },
        {
            "name": "Song Huang (黄淞)",
            "avatar": PIAvatar10,
            "introduction": "Visiting Ph.D. Candidate",
            "link": "/member/songhuang"
        },
        {
            "name": "Ziyi Han (韩紫怡)",
            "avatar": PIAvatar11,
            "introduction": "Visiting M.S. Candidate",
            "link": "/member/ziyihan"
        },
        {
            "name": "Wingni Cheng (郑咏妮)",
            "avatar": PIAvatar13,
            "introduction": "M.S. Candidate",
            "link": "/member/wingnicheng"
        },
        {
            "name": "Xiang Chen (陈湘)",
            "avatar": PIAvatar14,
            "introduction": "M.S. Candidate",
            "link": "/member/xiangchen"
        },
        {
            "name": "Shangtien Yang (杨赏恬)",
            "avatar": PIAvatar15,
            "introduction": "M.S. Candidate",
            "link": "/member/shangtienyang"
        },
        {
            "name": "Xuan Zhou (周萱)",
            "avatar": PIAvatar16,
            "introduction": "Visiting M.S. Candidate",
            "link": "/member/xuanzhou"
        },
        {
            "name": "Yuan Meng (孟媛)",
            "avatar": PIAvatar17,
            "introduction": "B.S. Candidate",
            "link": "/member/yuanmeng"
        },
        {
            "name": "Junxian Li (李俊贤)",
            "avatar": PIAvatar18,
            "introduction": "B.S. Candidate",
            "link": "/member/junxianli"
        },
        {
            "name": "Huijun Liu (刘慧军)",
            "avatar": PIAvatar19,
            "introduction": "Visiting M.S. Candidate",
            "link": "/member/huijunliu"
        },
        {
            "name": "Qingduo Wang (王庆铎)",
            "avatar": PIAvatar20,
            "introduction": "Visiting M.S. Candidate",
            "link": "/member/qingduowang"
        },
        {
            "name": "Yu liang (梁聿)",
            "avatar": PIAvatar21,
            "introduction": "B.S. Candidate",
            "link": "/member/yuliang"
        },
        {
            "name": "Ye Sun (孙烨)",
            "avatar": PIAvatar22,
            "introduction": "B.S. Candidate",
            "link": "/member/sunye"
        },
    ]
}

function get_card(member) {
    return (
        <Col md={4} className="project-card">
            <MemberCard
                avatar={member["avatar"]}
                name={member["name"]}
                introduction={member["introduction"]}
                link={member["link"]}
            />
        </Col>
    );
}
function display_members(members) {
    var cols = []
    var rows = []
    for(var i = 0; i < members.length; ++i) {
        var member = members[i];
        cols.push(get_card(member));
        if((i + 1) % 3 === 0) {
            rows.push(<Row>{cols}</Row>);
            cols = [];
        }
    }
    rows.push(<Row>{cols}</Row>);
    return <Container>{rows}</Container>
}

function Team() {
    return (
        <section>
            <Container fluid className={"project-section"}>
                <h1 className={"white-text"}>Team Members</h1>
            </Container>
            <Container style={{"textAlign": "left"}} className={"home-research-section"}>
                <h2>Principal Investigator</h2>
                {display_members(members["Principal Investigator"])}
            </Container>
            <Container style={{"textAlign": "left"}} className={"home-research-section"}>
                <h2>RESEARCH FELLOW</h2>
                {display_members(members["RESEARCH FELLOW"])}
            </Container>
        </section>
    )
}

export default Team;