import React, {useState} from "react";
//import Button from "react-bootstrap/Button";
import {Container, Row, Col, ButtonGroup, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import PaperItem from "./Paper";
import pub_list from "../../Assets/Publications/publications.json"


const calculateGlobalIndexes = (publications) => {
    let globalIndex = 1;
    // 将 "Before 2018" 视为 "0000" 来确保其最先排序
    const years = Object.keys(publications).map(year => year === "before_2018" ? "0000" : year).sort();
    // 创建一个新的排序后的 publications 对象
    const sortedPublications = {};
    for (let year of years) {
        // 将转换后的年份转换回来
        let originalYear = year === "0000" ? "before_2018" : year;
        sortedPublications[originalYear] = publications[originalYear].map(pub => ({
            ...pub,
            globalIndex: globalIndex++,
            phaseYear: originalYear // 添加 phaseYear 方便后续处理
        }));
    }
    return sortedPublications;
};

const pub_list_with_global_indexes = calculateGlobalIndexes(pub_list);
function CreatePublicationList(phase, selectedYear) {
    let pubs = [];

    if(phase === "all"){
        // 遍历处理过的 pub_list_with_global_indexes
        for (const year in pub_list_with_global_indexes) {
            pubs = pubs.concat(pub_list_with_global_indexes[year]);
        }
        pubs = pubs.filter(pub => pub["phaseYear"] === selectedYear);
        // 应用全局索引进行排序
        pubs.sort((a, b) => b.globalIndex - a.globalIndex);
        return (
            <section>
                {
                    pubs.map(pub => (
                        <PaperItem
                            key={pub.globalIndex}
                            index={pub.globalIndex}
                            title={pub["title"]}
                            author={pub["author"]}
                            reference={pub["reference"]}
                            image={`/image/${pub["phase"]}/${pub["phaseYear"]}/${pub["image"]}`}
                            link={`/pdf/${pub["phase"]}/${pub["phaseYear"]}/${pub["image"].split(".")[0]}.pdf`}
                        />
                    ))
                }
            </section>
        );
    } else {
        // 对于特定阶段，按年份过滤
        pubs = pub_list[selectedYear]
        pubs = pubs.filter(pub => pub["phase"] === phase);
        let min_index = pubs.sort((a, b) => a["index"] - b["index"])[0]["index"]
        return (
            <section>
                {
                    pubs.sort((a, b) => b["index"] - a["index"])
                        .map(pub => (
                            <PaperItem
                                key={pub["index"] - min_index + 1}
                                index={pub["index"] - min_index + 1}
                                title={pub["title"]}
                                author={pub["author"]}
                                reference={pub["reference"]}
                                image={`/image/${pub["phase"]}/${selectedYear}/${pub["image"]}`}
                                link={`/pdf/${pub["phase"]}/${selectedYear}/${pub["image"].split(".")[0]}.pdf`}
                            />
                        ))
                }
            </section>
        );
    }
}



function Publications() {
    const [phase, setPhase] = useState("all");
    const [year, setYear] = useState("2024");
    const default_year = {
        "before_pku": "2022",
        "pku": "2024",
        "all": "2024"
    };

    const phase_radio = [
        {name: 'All', value: 'all'},
        {name: 'After Joining PKU', value: 'pku'},
        {name: 'Before Joining PKU', value: 'before_pku'}
    ];

    const pku_year_radio = [
        {name: "2024", value: "2024"},
        {name: "2023", value: "2023"},
        {name: "2022", value: "2022"}
    ];

    const before_pku_year_radio = [
        {name: "2022", value: "2022" },
        { name: "2021", value: "2021" },
        { name: "2020", value: "2020" },
        { name: "2019", value: "2019" },
        { name: "2018", value: "2018" },
        { name: "Before 2018", value: "before_2018" },
    ];

    const all_year_radio = [
        { name: "2024", value: "2024" },
        { name: "2023", value: "2023" },
        { name: "2022", value: "2022" },
        { name: "2021", value: "2021" },
        { name: "2020", value: "2020" },
        { name: "2019", value: "2019" },
        { name: "2018", value: "2018" },
        { name: "Before 2018", value: "before_2018" },
    ];

    const phase_and_year = [
        {"phase": "pku", "year": "2024"},
        {"phase": "pku", "year": "2023"},
        {"phase": "pku", "year": "2022"},
        {"phase": "before_pku", "year": "2022"},
        {"phase": "before_pku", "year": "2021"},
        {"phase": "before_pku", "year": "2020"},
        {"phase": "before_pku", "year": "2019"},
        {"phase": "before_pku", "year": "2018"},
        {"phase": "before_pku", "year": "before_2018"},
        {"phase": "all", "year": "2024"},
        {"phase": "all", "year": "2023"},
        {"phase": "all", "year": "2022"},
        {"phase": "all", "year": "2021"},
        {"phase": "all", "year": "2020"},
        {"phase": "all", "year": "2019"},
        {"phase": "all", "year": "2018"},
        {"phase": "all", "year": "before_2018"},

    ];

    return (
        <section>
            <Container fluid className="project-section">
                <h1 className={"white-text"}>Publications</h1>
            </Container>
            <Container className={"home-research-section"}>
                <Row>
                    <Col md={4}>
                        <ButtonGroup>
                            {
                                phase_radio.map(
                                    (radio, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            id={`phase-${idx}`}
                                            type="radio"
                                            variant="outline-success"
                                            name={"radio"}
                                            value={radio.value}
                                            checked={phase === radio.value}
                                            onChange={(e) =>
                                                {
                                                    setPhase(e.currentTarget.value);
                                                    setYear(default_year[radio.value]);
                                                }
                                            }
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                    )
                                )
                            }
                        </ButtonGroup>
                    </Col>
                    <Col md={6} style={{display:'flex', justifyContent:'right'}}>
                        {phase === "pku" && (
                            <ButtonGroup>
                                {
                                    pku_year_radio.map(
                                        (radio, idx) => (
                                            <ToggleButton
                                                key={idx}
                                                id={`pku-year-${idx}`}
                                                type="radio"
                                                variant="info"
                                                name={"radio"}
                                                value={radio.value}
                                                checked={year === radio.value}
                                                onChange={(e) => {
                                                    setYear(e.currentTarget.value)
                                                }}
                                            >
                                                {radio.name}
                                            </ToggleButton>
                                        )
                                    )
                                }
                            </ButtonGroup>
                        )
                        }
                        {
                            phase === "before_pku" && (
                                <ButtonGroup>
                                    {
                                        before_pku_year_radio.map(
                                            (radio, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`before-pku-${idx}`}
                                                    type="radio"
                                                    variant="info"
                                                    name={"radio"}
                                                    value={radio.value}
                                                    checked={year === radio.value}
                                                    onChange={(e) => setYear(e.currentTarget.value)}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            )
                                        )
                                    }
                                </ButtonGroup>
                            )
                        }
                        {
                            phase === "all" && (
                                <ButtonGroup>
                                    {
                                        all_year_radio.map(
                                            (radio, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`all-year-${idx}`}
                                                    type="radio"
                                                    variant="info"
                                                    name={"radio"}
                                                    value={radio.value}
                                                    checked={year === radio.value}
                                                    onChange={(e) => setYear(e.currentTarget.value)}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            )
                                        )
                                    }
                                </ButtonGroup>
                            )
                        }
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{width: "100%"}}>
            {
                //CreatePublicationList(phase, year)
                phase_and_year.map(e => (phase === e["phase"] && year === e["year"] && CreatePublicationList(e["phase"], e["year"])))
            }
            </Container>
        </section>
    );
}

export default Publications;
