import React from "react";
import {Container} from "react-bootstrap";
import ImageGallery from 'react-image-gallery';

const images = [
    {
        original: '/image/gallery/1.jpg',
        thumbnail: '/image/gallery/1.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/2.jpg',
        thumbnail: '/image/gallery/2.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/3.jpg',
        thumbnail: '/image/gallery/3.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/4.jpg',
        thumbnail: '/image/gallery/4.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/5.jpg',
        thumbnail: '/image/gallery/5.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/6.jpg',
        thumbnail: '/image/gallery/6.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/9.jpg',
        thumbnail: '/image/gallery/9.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/10.jpg',
        thumbnail: '/image/gallery/10.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/11.jpg',
        thumbnail: '/image/gallery/11.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/12.jpg',
        thumbnail: '/image/gallery/12.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
    {
        original: '/image/gallery/13.jpg',
        thumbnail: '/image/gallery/13.jpg',
        originalHeight: '768px',
        originalWidth: '1080px',
    },
];

function MyGallery() {
    return (
        <section>
        <Container fluid className={"project-section"}>
            <h1 className={"white-text"}>Gallery</h1>
        </Container>
        <Container fluid className={"gallery-section"}>
            <ImageGallery items={images} />
        </Container>
        </section>
    )
}

export default MyGallery;