import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import HighlightCard from "./HighlighCard";
import highlight1 from "../../Assets/Home/highlight-1.jpg";
import highlight2 from "../../Assets/Home/highlight-2.jpg";
import highlight3 from "../../Assets/Home/highlight-3.jpg";
import highlight4 from "../../Assets/Home/highlight-4.jpg";
import highlight5 from "../../Assets/Home/highlight-5.jpg";
import highlight6 from "../../Assets/Home/highlight-6.jpg";



function Highlight() {
    return (
        <Container fluid className="home-research-section" id="research">
            <Container>
                <Row>
                    <Col md={12}>
                        <h1 style={{marginBottom: "30px", marginTop: "-10px"}}>
                            Research Highlights
                        </h1>
                    </Col>
                </Row>

                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    <Col md={2} className="project-card">
                        <HighlightCard
                            imgPath={highlight1}
                            title={"Nat. Nanotechnol. 2022, 17, 174–181."}
                            link={"https://www.google.com"}
                        />
                    </Col>
                    <Col md={2} className="project-card">
                        <HighlightCard
                            imgPath={highlight2}
                            title={"Nature. 2020, 581, 171–177."}
                            link={"/pdf/before_pku/2020/2020-3.pdf"}
                        />
                    </Col>
                    <Col md={2} className="project-card">
                        <HighlightCard
                            imgPath={highlight3}
                            title={"Nat. Chem. 2020, 12, 1115–1122."}
                            link={"/pdf/before_pku/2020/2020-13.pdf"}
                        />
                    </Col>
                    <Col md={2} className="project-card">
                        <HighlightCard
                            imgPath={highlight4}
                            title={"Adv. Mater. 2019, 31, 1900237."}
                            link={"/pdf/before_pku/2019/2019-9.pdf"}
                        />
                    </Col>
                    <Col md={2} className="project-card">
                        <HighlightCard
                            imgPath={highlight5}
                            title={"Adv. Mater. 2018, 30, 1802397."}
                            link={"/pdf/before_pku/2018/2018-4.pdf"}
                        />
                    </Col>
                    <Col md={2} className="project-card">
                        <HighlightCard
                            imgPath={highlight6}
                            title={"Adv. Mater. 2018, 30, 1707281."}
                            link={"/pdf/before_pku/2018/2018-7.pdf"}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Highlight;