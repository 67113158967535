import React from "react";
import {Container, Row, Col} from "react-bootstrap";

function Contact() {
    return (
        <Container fluid className="home-about-section" id="contact">
            <Container>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1 style={{marginBottom: "20px", marginTop: "-10px"}}>
                            Contact Us
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <p>
                            PEKING UNIVERSITY ZHAO LABORATORY / No.5 Yiheyuan Road, Beijing 100871, China
                        </p>
                        <p>
                            Email: xiaoxuzhao@pku.edu.cn
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Contact;

